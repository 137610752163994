import React, { useState } from 'react';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Svg from '../../component/common/svg/EditIcon';
import { Constant } from '../../utilities';
import EditDeliveryBoyDetails from './EditDeliveryBoyDetails';
import { CustomTable } from '../../component/common';

const DeliveryBoyDetails = ({ deliveryBoyDetails, storeCode, handleRequestProcessing }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState('');
  const [deliveryBoyDetailsModal, setDeliveryBoyDetailsModal] = useState(false);

  const handleEdit = (deliveryBoyId) => {
    setEditingId(deliveryBoyId);
    setIsEditing(true);
  };

  const headerConfig = [
    {
      key: 'name',
      displayText: 'Name',
      renderer: (rowData) => (
        <>
          {rowData.isPrimary && (
            <span className="text-danger">
              *
            </span>
          )}
          <span
            className="font-weight-bold"
          >
            {rowData.name}
          </span>
        </>
      ),
    },
    {
      key: 'phoneNumber',
      displayText: 'Phone Number',
    },
    {
      key: 'id',
      displayText: 'Edit',
      renderer: (rowData) => (
        <Button
          variant="outline"
          onClick={() => handleEdit(rowData.deliveryBoyId, rowData)}
          className="edit-icon"
        >
          <Svg
            svg="editIcon"
            height={22}
            width={22}
            stroke={Constant.Color.BLACK}
          />
        </Button>
      ),
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col
          lg="9"
          className="px-2"
        >
          <CustomTable
            headers={headerConfig}
            keyField="deliveryBoyId"
            content={deliveryBoyDetails}
            isPaginated={false}
            totalItems={deliveryBoyDetails?.length}
            size="md"
          />
        </Col>
        <Col>
          {
            (deliveryBoyDetailsModal || isEditing) && (
              <EditDeliveryBoyDetails
                show={deliveryBoyDetailsModal || isEditing}
                onHide={() => {
                  setDeliveryBoyDetailsModal(false);
                  setIsEditing(false);
                }}
                handleRequestProcessing={handleRequestProcessing}
                id={editingId}
                storeCode={storeCode}
                editDeliveryBoyDetails={isEditing}
              />
            )
          }
        </Col>
      </Row>
      <Row
        className="px-2 pb-2"
      >
        {deliveryBoyDetails?.length < 4 && (
        <Button
          type="button"
          variant="outline-primary"
          className="fs-1"
          onClick={() => {
            setDeliveryBoyDetailsModal(true);
          }}
        >
          Add Delivery Boy Details
        </Button>
      )}
      </Row>
      <Row
        className="px-2"
      >
        <span className="text-danger">*</span>
        <span>Refers to primary delivery boy</span>
      </Row>
    </Container>
  );
};

DeliveryBoyDetails.propTypes = {
  deliveryBoyDetails: PropTypes.arrayOf(
    PropTypes.shape({
      boy: PropTypes.string.isRequired,
    }),
  ).isRequired,
  storeCode: PropTypes.string.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default DeliveryBoyDetails;
