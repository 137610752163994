import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Container, Row, Col, Spinner,
} from 'react-bootstrap';
import {
  CustomDropdown,
  CustomModal, ErrorHandler,
} from '../../component/common';
import { deliveryBoy } from '../../assets/api/axios';
import { validateRegex } from '../../utilities/FormValidations';

const EditDeliveryBoyDetails = ({
  id, show, onHide, storeCode, editDeliveryBoyDetails,
  handleRequestProcessing,
}) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [isPrimary, setIsPrimary] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [loadStatus, setLoadStatus] = useState('error');

  const disabled = submitting || ((name?.length === 0)
  || (phoneNumber?.toString().length === 0));

  const isPrimaryOptions = {
    key: 'isPrimary',
    displayText: '',
    options: [
      {
        label: 'True',
        value: 'true',
      },
      {
        label: 'False',
        value: 'false',
      },
    ],
  };

  const onClose = () => {
    setName(null);
    setPhoneNumber(null);
    setSubmitting(false);
    setSubmitError('');
    setLoadStatus('');
    onHide();
    handleRequestProcessing();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {};
    if (editDeliveryBoyDetails) {
      data = {
        deliveryBoyId: id,
        isPrimary,
        isDeleted,
      };
    }
    setSubmitting(true);
    setSubmitError('');
    deliveryBoy(
      editDeliveryBoyDetails ? 'PATCH' : 'POST',
      storeCode,
      {
        ...data,
        name,
        phoneNumber,
      },
      {},
    ).then((res) => {
      if (res.status === 200) {
        onClose();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops something went wrong. Retry!';
      if (err?.response?.data?.errors[0]?.message) {
        errorMsg = err.response.data.errors[0].message;
      }
      setSubmitting(false);
      setSubmitError(errorMsg);
    });
  };

  const handleLoad = () => {
    setLoadStatus('loading');
    deliveryBoy(
      'GET',
      storeCode,
      {},
      {
        deliveryBoyId: id,
      },
    ).then((res) => {
      const data = res?.data?.data[0];
      setName(data?.name);
      setPhoneNumber(data?.phoneNumber);
      setIsPrimary(data?.isPrimary);
      setLoadStatus('');
    }).catch(() => {
      setLoadStatus('error');
    });
  };

  useEffect(() => {
    if (editDeliveryBoyDetails) {
      handleLoad();
    }
  }, []);

  const body = (
    <Container className="px-4 py-4">
      <Row>
        {(() => {
          let showcase = null;
          if (editDeliveryBoyDetails && (loadStatus === 'loading')) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                </div>
              </Col>
            );
          } else if (editDeliveryBoyDetails && (loadStatus === 'error')) {
            showcase = (
              <Col>
                <div
                  className="h-100 d-flex align-items-center
                  justify-content-center"
                >
                  <ErrorHandler
                    retryLogic={() => {
                      handleLoad();
                    }}
                  />
                </div>
              </Col>
            );
          } else {
            showcase = (
              <Col>
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {editDeliveryBoyDetails && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={6}>
                      <b>Delivery Boy Id</b>
                    </Form.Label>
                    <Col sm={9}>
                      <div>
                        {id}
                      </div>
                    </Col>
                  </Form.Group>
                  )}
                  {
                    <Form.Group as={Row}>
                      <Form.Label column sm={6}>
                        <b>Name</b>
                      </Form.Label>
                      <Col sm={9}>
                        <input
                          type="text"
                          placeholder="Enter Delivery Boy's Name here"
                          value={name}
                          id="name"
                          onChange={(e) => setName(e.target.value)}
                          autoComplete="off"
                          className="form-control fs-01"
                        />
                      </Col>
                    </Form.Group>
                  }
                  {
                    (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6} className="fs-1">
                          <b>Phone Number</b>
                        </Form.Label>
                        <Col sm="12">
                          <Row className="py-1">
                            <Col>
                              <input
                                type="text"
                                placeholder="Enter Phone Number"
                                value={phoneNumber}
                                id="phoneNumber"
                                onChange={(e) => {
                                  if (validateRegex('integerValue', e.target.value)) {
                                    setPhoneNumber(e.target.value);
                                  }
                                }}
                                autoComplete="off"
                                className="form-control fs-01"
                                maxLength="10"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>
                    )
                  }
                  {
                    editDeliveryBoyDetails && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6}>
                          <b> Is Primary</b>
                        </Form.Label>
                        <Col sm={9}>
                          <CustomDropdown
                            item={isPrimaryOptions}
                            name="isPrimary"
                            onChange={(item) => setIsPrimary(item.isPrimary)}
                            selectedVal={isPrimary?.toString()}
                            closeButton={false}
                          />
                        </Col>
                      </Form.Group>
                    )
                  }
                  {editDeliveryBoyDetails
                    && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={6} className="fs-1">
                          <b>Remove Details</b>
                        </Form.Label>
                        <Col sm="12">
                          <Row className="py-1">
                            <Col
                              lg="6"
                              md="9"
                              sm="auto"
                            >
                              <Button
                                block
                                variant="primary"
                                type="submit"
                                className="fs-01"
                                onClick={() => { setIsDeleted(true); }}
                              >
                                <span>
                                  Delete
                                </span>
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>
                    )}
                  {
                    submitting && (
                      <div
                        className="d-flex align-item-center justify-content-center"
                      >
                        <Col sm={6} />
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    )
                  }
                  {
                    submitError && (
                      <div
                        className="d-flex align-item-center justify-content-center fs-0 py-2"
                      >
                        <Col sm={6} />
                        <span className="text-danger">
                          {submitError}
                        </span>
                      </div>
                    )
                  }
                  <Form.Group as={Row}>
                    <Form.Label column sm={6} />
                    <Col>
                      <Button
                        block
                        variant="primary"
                        type="submit"
                        className="fs-01"
                        disabled={disabled}
                      >
                        {editDeliveryBoyDetails ? 'SAVE CHANGES' : 'CREATE'}
                      </Button>
                    </Col>
                  </Form.Group>

                </Form>
              </Col>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );

  return (
    <CustomModal
      show={show}
      title={`${editDeliveryBoyDetails ? 'Edit' : 'Add'} Delivery Boy Details`}
      onHide={() => onHide()}
      size="lg"
      body={body}
      closeButton
    />
  );
};

EditDeliveryBoyDetails.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  storeCode: PropTypes.string.isRequired,
  editDeliveryBoyDetails: PropTypes.bool.isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default EditDeliveryBoyDetails;
