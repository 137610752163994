import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner, InputGroup, Form,
} from 'react-bootstrap';
import QueryString from 'query-string';
import { Svg, CustomTable, ErrorHandler } from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import CreateSpecialNotes from './CreateSpecialNotes';
import permission from '../../access&permissions/permission';
import { specialNotes } from '../../assets/api/axios';
import Constant from '../../utilities/Constant';
import { Utils } from '../../utilities';
import { useStateWithCallback } from '../../utilities/Utils';

function renderStatus(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    l, p,
  });
};

function SpecialNotes(props) {
  const { userPermission, history } = props;
  const { pathname } = history.location;
  const param = getQueryParams(history.location.search);

  const [specialNote, setSpecialNote] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(param?.l);
  const [page, setPage] = useState(param?.p);
  const [storeCode, setStoreId] = useState('');
  const [params, setParams] = useStateWithCallback(param);
  const [newParams] = useState(param);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [specialNoteModal, setSpecialNoteModal] = useState(false);
  const [editSpecialNotes, setEditSpecialNotes] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const canCreate = userPermission.includes(permission.SPECIAL_NOTES_WRITE);
  const handleLoad = (data = {}) => {
    setLoading(true);
    setError(false);
    const updatedParams = {
      ...params,
      ...newParams,
      ...data,
    };
    const {
      l, p,
    } = updatedParams;
    specialNotes(
      'GET',
      null,
      {
        page: p,
        limit: l,
        storeId: storeCode,
      },
    ).then((res) => {
      setSpecialNote(res.data);
      setLoading(false);
      setParams({
        ...updatedParams,
      }, () => {
        if (Object.keys(updatedParams).find((key) => (updatedParams[key] !== params[key]))) {
          Object.keys(updatedParams).forEach((item) => {
            if (!updatedParams[item]) {
              delete updatedParams[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(updatedParams),
          });
        }
      });
    }).catch(() => {
      setLoading(false);
      setError(true);
    });
  };

  const handleRequestProcessing = (data = {}) => {
    handleLoad(data);
  };

  useEffect(() => {
    handleLoad();
    // eslint-disable-next-line
  }, []);

  const retry = () => {
    handleRequestProcessing();
  };

  const cancel = () => {
    setLoading(false);
    setError(false);
  };

  const onNext = () => {
    if (params.p + 1 !== params.p) {
      handleRequestProcessing({ p: params.p + 1 });
    }
  };

  const onPrev = () => {
    if (params.p - 1 !== params.p) {
      handleRequestProcessing({ p: params.p - 1 });
    }
  };

  const handleRowsPageInput = (value, field) => {
    if (field === 'rowsPerPage') {
      setRowsPerPage(value);
    } else {
      setPage(value);
    }
  };

  const handleChange = (value) => {
    setStoreId(value);
  };

  const onSubmitRowsPerPage = () => {
    if (rowsPerPage !== params.l) {
      handleRequestProcessing({ l: rowsPerPage });
    }
  };

  const onSubmitPage = () => {
    if (page !== params.p) {
      handleRequestProcessing({ p: page });
    }
  };

  const headers = [
    {
      key: 'specialNoteId',
      displayText: 'Id',
      renderer: (data) => (
        <Button
          variant="link"
          onClick={() => {
            setEditSpecialNotes(true);
            setSelectedId(data.specialNoteId);
          }}
          className="fs-01 p-0"
          disabled={!canCreate}
        >
          {data.specialNoteId}
        </Button>
      ),
    },
    {
      key: 'created_at',
      displayText: 'Created At',
      renderer: (data) => Utils.dateString(data.created_at),
    },
    {
      key: 'modifiedAt',
      displayText: 'Modified At',
      renderer: (data) => Utils.dateString(data.modified_at),
    },
    {
      key: 'isActive',
      displayText: 'Active',
      renderer: (data) => renderStatus(data.active),
    },
    {
      key: 'hexIds',
      displayText: 'Hex Ids',
      renderer: (data) => (
        <>
          {
            (data.hexIds).map((item) => (
              <div>
                {item}
              </div>
            ))
          }
        </>
      ),
    },
    {
      key: 'message',
      displayText: 'Message',
      renderer: (data) => (
        <div
          className="py-1 text-truncate-2"
        >
          {data.message}
        </div>

      ),
    },
    {
      key: 'storeTypes',
      displayText: 'Store Types',
      renderer: (data) => (
        <>
          {
            (data.storeTypes).map((item) => (
              <div>
                {item}
              </div>
            ))
          }
        </>
      ),
    },
  ];

  return (
    <Container
      fluid
      className="h-100 bg-white"
    >
      {
        (specialNoteModal || editSpecialNotes) && (
          <CreateSpecialNotes
            show={specialNoteModal || editSpecialNotes}
            onHide={() => {
              setSpecialNoteModal(false);
              setEditSpecialNotes(false);
            }}
            handleRequestProcessing={handleRequestProcessing}
            specialNoteId={selectedId}
            editSpecialNotes={editSpecialNotes}
          />
        )
      }
      <Row
        className="h-100 flex-column"
      >
        <Col
          className="flex-grow-0"
        >
          <Row
            className="align-items-center py-2"
          >
            <Col
              xs="auto"
              className="px-2 py-2"
            >
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    className="rounded-0"
                  >
                    <Svg
                      svg="search"
                      width="1rem"
                      fill={Constant.Color.DARK}
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Store Id"
                  name="storeCode"
                  className="fs-01 rounded-0"
                  // value={specialNote?.data.map((item)=>{
                  //   item.storeIds.map(())
                  // })}
                  // onChange={handleChange}
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                  onKeyPress={
                    (e) => {
                      if (e.which === 13) {
                        handleLoad();
                      }
                    }
                  }
                />
              </InputGroup>
            </Col>
            <Col
              className="d-flex justify-content-end mt-2"
            >
              <Button
                variant="outline-primary"
                className="fs-1"
                onClick={() => {
                  setSpecialNoteModal(true);
                }}
                disabled={!canCreate}
              >
                CREATE SPECIAL NOTE
              </Button>
            </Col>
          </Row>
        </Col>
        {(() => {
          let showcase = null;
          if (!specialNote && loading) {
            showcase = (
              <div
                className="d-flex align-items-center
                justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!specialNote && !loading && error) {
            showcase = (
              <div
                className="d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => retry()}
                />
              </div>
            );
          } else if (specialNote) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={() => retry()}
                  onCancel={() => cancel()}
                />
                <Col
                  className="px-0 flex-grow-1 overflow-y pt-2"
                >
                  <CustomTable
                    headers={headers}
                    content={specialNote.data}
                    keyField="specialNoteId"
                    l={params.l}
                    p={params.p}
                    rowsPerPage={rowsPerPage}
                    page={params.p}
                    totalItems={specialNote?.count}
                    hasPrev={!!specialNote?.prev}
                    hasNext={!!specialNote?.next}
                    onNext={onNext}
                    onPrev={onPrev}
                    onSubmitPage={onSubmitPage}
                    onSubmitRowsPerPage={onSubmitRowsPerPage}
                    updateRowsPageInput={handleRowsPageInput}
                  />
                </Col>
              </>
            );
          }
          return showcase;
        })()}
      </Row>
    </Container>
  );
}

SpecialNotes.propTypes = {
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
};

export default SpecialNotes;
