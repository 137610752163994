const permission = {
  // other permissions
  ADD_PRODUCT: 'ADD_PRODUCT',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  CALL_TO_RETAILER: 'CALL_TO_RETAILER',
  CALL_TO_CUSTOMER: 'CALL_TO_CUSTOMER',
  BULK_PRODUCT_ADDITION: 'BULK_PRODUCT_ADDITION',
  EDIT_DELIVERY_SETTING: 'EDIT_DELIVERY_SETTING',
  ALLOCATE_SHOP: 'ALLOCATE_SHOP',
  EDIT_ORDER: 'EDIT_ORDER',
  PAY_SETTLEMENTS: 'PAY_SETTLEMENTS',
  SHIFT_DUE_DATE: 'SHIFT_DUE_DATE',
  FLASH_SALE_READY_ONLY: 'FLASH_SALE_READY_ONLY',
  FRAUD_AGENT: 'FRAUD_AGENT',
  FRAUD_ADMIN: 'FRAUD_ADMIN',
  MARK_TEST_SHOP: 'MARK_TEST_SHOP',
  SMART_CATALOGUES_WRITE_RETROACTIVE: 'SMART_CATALOGUES_WRITE_RETROACTIVE',
  LIBRARY_PRODUCTS_WRITE_RETROACTIVE: 'LIBRARY_PRODUCTS_WRITE_RETROACTIVE',
  SMART_CATALOGUE_MAP_WRITE_RETROACTIVE: 'SMART_CATALOGUE_MAP_WRITE_RETROACTIVE',
  SMART_CATALOGUES_STORE_WRITE_RETROACTIVE: 'SMART_CATALOGUES_STORE_WRITE_RETROACTIVE',
  LIBRARY_PRODUCT_REGIONAL_PRICE_WRITE_RETROACTIVE: 'LIBRARY_PRODUCT_REGIONAL_PRICE_WRITE_RETROACTIVE',
  LEAD_MANAGEMENT_ADMIN: 'LEAD_MANAGEMENT_ADMIN',
  MOVE_AGENT_ASSIGNMENT: 'MOVE_AGENT_ASSIGNMENT',
  MODIFY_KYC_STATUS: 'MODIFY_KYC_STATUS',
  RETAILER_EXPLICIT_PERMISSION_ON_STORE_NAME: 'RETAILER_EXPLICIT_PERMISSION_ON_STORE_NAME',
  RETAILER_EXPLICIT_PERMISSION_ON_OWNER_NAME: 'RETAILER_EXPLICIT_PERMISSION_ON_OWNER_NAME',
  RETAILER_EXPLICIT_PERMISSION_ON_STORE_DISPLAY_NAME: 'RETAILER_EXPLICIT_PERMISSION_ON_STORE_DISPLAY_NAME',
  RETAILER_EXPLICIT_PERMISSION_ON_PRIMARY_PHONE: 'RETAILER_EXPLICIT_PERMISSION_ON_PRIMARY_PHONE',
  RETAILER_PERMISSION_ON_SECONDARY_PHONE: 'RETAILER_PERMISSION_ON_SECONDARY_PHONE',
  RETAILER_PERMISSION_ON_WHATSAPP_NUMBER: 'RETAILER_PERMISSION_ON_WHATSAPP_NUMBER',
  RETAILER_PERMISSION_ON_WHATSAPP_NOTIFICATION_OPTED: 'RETAILER_PERMISSION_ON_WHATSAPP_NOTIFICATION_OPTED',
  RETAILER_EXPLICIT_PERMISSION_ON_SUBSCRIPTION_PLAN: 'RETAILER_EXPLICIT_PERMISSION_ON_SUBSCRIPTION_PLAN',
  RETAILER_PERMISSION_ON_CATEGORY: 'RETAILER_PERMISSION_ON_CATEGORY',
  RETAILER_PERMISSION_ON_STORE_ADDRESS: 'RETAILER_PERMISSION_ON_STORE_ADDRESS',
  RETAILER_PERMISSION_ON_DELIVERY_TIME: 'RETAILER_PERMISSION_ON_DELIVERY_TIME',
  RETAILER_PERMISSION_ON_KNOWN_RETAILER: 'RETAILER_PERMISSION_ON_KNOWN_RETAILER',
  RETAILER_PERMISSION_ON_GENERAL_NOTE: 'RETAILER_PERMISSION_ON_GENERAL_NOTE',
  RETAILER_PERMISSION_ON_LAST_RETAILER_COMMENT: 'RETAILER_PERMISSION_ON_LAST_RETAILER_COMMENT',
  RETAILER_EXPLICIT_PERMISSION_ON_BDE_LEAD: 'RETAILER_EXPLICIT_PERMISSION_ON_BDE_LEAD',
  RETAILER_PERMISSION_ON_CREATE_TEST_ORDER_BUTTON: 'RETAILER_PERMISSION_ON_CREATE_TEST_ORDER_BUTTON',
  RETAILER_PERMISSION_ON_RUN_FRAUD_CHECK: 'RETAILER_PERMISSION_ON_RUN_FRAUD_CHECK',
  RETAILER_PERMISSION_ON_SPECIAL_OFFER_PAGE: 'RETAILER_PERMISSION_ON_SPECIAL_OFFER_PAGE',
  ONBOARDING_EXPLICIT_PERMISSION_ON_FUND_ACCOUNT_DETAILS: 'ONBOARDING_EXPLICIT_PERMISSION_ON_FUND_ACCOUNT_DETAILS',
  EXPLICIT_PERMISSION_ON_LAUNCH_SHOP_BUTTON: 'EXPLICIT_PERMISSION_ON_LAUNCH_SHOP_BUTTON',
  ONBOARDING_EXPLICIT_PERMISSION_ON_BANK_DETAIL_SECTION: 'ONBOARDING_EXPLICIT_PERMISSION_ON_BANK_DETAIL_SECTION',
  ONBOARDING_EXPLICIT_PERMISSION_ON_STORY_SECTION: 'ONBOARDING_EXPLICIT_PERMISSION_ON_STORY_SECTION',
  RETAILER_SETTING_DELIVERY_ON_OFF_ACCESS: 'RETAILER_SETTING_DELIVERY_ON_OFF_ACCESS',
  RETAILER_SETTING_ACCOUNT_DETAIL_WRITE: 'RETAILER_SETTING_ACCOUNT_DETAIL_WRITE',
  RETAILER_SETTING_STORE_DETAIL_WRITE: 'RETAILER_SETTING_STORE_DETAIL_WRITE',
  TRAINING_ALLOCATION_WRITE: 'TRAINING_ALLOCATION_WRITE',
  RETAILER_PERMISSION_ON_ORDER_AUTO_ACCEPTANCE: 'RETAILER_PERMISSION_ON_ORDER_AUTO_ACCEPTANCE',
  FORCE_ADD_BULK_UPLOAD: 'FORCE_ADD_BULK_UPLOAD',

  // read permissions
  SHARE_OFFER_CARD_READ: 'SHARE_OFFER_CARD_READ',
  SMS_SENT_READ: 'SMS_SENT_READ',
  BANNERS_READ: 'BANNERS_READ',
  CUSTOMERS_REVIEW_READ: 'CUSTOMERS_REVIEW_READ',
  PENDING_SETTLEMENTS_READ: 'PENDING_SETTLEMENTS_READ',
  RECONCILED_SETTLEMENTS_READ: 'RECONCILED_SETTLEMENTS_READ',
  DELIVERY_3_PL_SETTLEMENTS_READ: 'DELIVERY_3_PL_SETTLEMENTS_READ',
  PENDING_SETTLEMENTS_STORE_READ: 'PENDING_SETTLEMENTS_STORE_READ',
  FEEDS_SECTION_READ: 'FEEDS_SECTION_READ',
  GENERIC_BULK_UPLOAD_READ: 'GENERIC_BULK_UPLOAD_READ',
  COUPON_COUPONID_READ: 'COUPON_COUPONID_READ',
  COUPON_READ: 'COUPON_READ',
  COUPON_TRACKER_READ: 'COUPON_TRACKER_READ',
  QUERY_TRACKER_READ: 'QUERY_TRACKER_READ',
  REFERRAL_CHECK_READ: 'REFERRAL_CHECK_READ',
  SIGNIN_READ: 'SIGNIN_READ',
  PRODUCT_BULK_UPLOAD_READ: 'PRODUCT_BULK_UPLOAD_READ',
  PRICE_UPDATE_READ: 'PRICE_UPDATE_READ',
  PRODUCT_ADD_READ: 'PRODUCT_ADD_READ',
  STORE_WISE_JOB_STATUS_READ: 'STORE_WISE_JOB_STATUS_READ',
  SHOP_LOGO_REVIEW_READ: 'SHOP_LOGO_REVIEW_READ',
  RETAILER_STORY_REVIEW_READ: 'RETAILER_STORY_REVIEW_READ',
  FLASH_SALE_DETAILED_READ: 'FLASH_SALE_DETAILED_READ',
  FLASH_SALE_AGGREGATED_READ: 'FLASH_SALE_AGGREGATED_READ',
  STORIES_READ: 'STORIES_READ',
  CUSTOMER_READ: 'CUSTOMER_READ',
  REFUND_DASHBOARD_READ: 'REFUND_DASHBOARD_READ',
  SMART_CATALOGUES_READ: 'SMART_CATALOGUES_READ',
  SMART_CATALOGUES_STORE_READ: 'SMART_CATALOGUES_STORE_READ',
  SMART_CATALOGUE_MAP_READ: 'SMART_CATALOGUE_MAP_READ',
  LIBRARY_PRODUCT_REGIONAL_PRICE_READ: 'LIBRARY_PRODUCT_REGIONAL_PRICE_READ',
  LIBRARY_PRODUCTS_READ: 'LIBRARY_PRODUCTS_READ',
  BACKGROUND_JOBS_READ: 'BACKGROUND_JOBS_READ',
  LOVELOCAL_DAILY_READ: 'LOVELOCAL_DAILY_READ',
  COUPON_CREATION_READ: 'COUPON_CREATION_READ',
  CREATE_OFFER_READ: 'CREATE_OFFER_READ',
  PLACE_ORDER_READ: 'PLACE_ORDER_READ',
  RETAILER_READ: 'RETAILER_READ',
  RETAILER_SETTING_READ: 'RETAILER_SETTING_READ',
  REVIEW_PANEL_READ: 'REVIEW_PANEL_READ',
  SHOP_ALLOCATION_READ: 'SHOP_ALLOCATION_READ',
  STORES_REVIEW_READ: 'STORES_REVIEW_READ',
  FESTIVE_CATEGORY_READ: 'FESTIVE_CATEGORY_READ',

  LEAD_MANAGEMENT_READ: 'LEAD_MANAGEMENT_READ',
  CUSTOMER_PANEL_READ: 'CUSTOMER_PANEL_READ',
  FRAUD_MANAGEMENT_READ: 'FRAUD_MANAGEMENT_READ',
  EXPORT_CLEANUP_TOOL_READ: 'EXPORT_CLEANUP_TOOL_READ',
  SHOP_CATALOGUE_PAGE_READ: 'SHOP_CATALOGUE_PAGE_READ',
  EXPORT_CATALOG_PRODUCTS_READ: 'EXPORT_CATALOG_PRODUCTS_READ',
  ORDERS_READ: 'ORDERS_READ',
  OM_STATUS_TRACKER_READ: 'OM_STATUS_TRACKER_READ',
  LEADERSHIP_BOARD_READ: 'LEADERSHIP_BOARD_READ',
  CAMPAIGN_ANALYTICS_READ: 'CAMPAIGN_ANALYTICS_READ',
  COUPON_ENGINE_READ: 'COUPON_ENGINE_READ',
  CX_RX_CALL_MAPPING_READ: 'CX_RX_CALL_MAPPING_READ',
  BRAND_SAMPLING_CAMPAIGN_READ: 'BRAND_SAMPLING_CAMPAIGN_READ',
  FLASH_SALE_CAMPAIGN_READ: 'FLASH_SALE_CAMPAIGN_READ',
  SPECIAL_NOTES_READ: 'SPECIAL_NOTES_READ',
  STORE_MANAGEMENT_READ: 'STORE_MANAGEMENT_READ',

  // write permissions
  CREATE_OFFER_WRITE: 'CREATE_OFFER_WRITE',
  SHOP_ALLOCATION_WRITE: 'SHOP_ALLOCATION_WRITE',
  RETAILER_CODE_WRITE: 'RETAILER_CODE_WRITE',
  RETAILER_WRITE: 'RETAILER_WRITE',
  RETAILER_SETTING_WRITE: 'RETAILER_SETTING_WRITE',
  PLACE_ORDER_WRITE: 'PLACE_ORDER_WRITE',
  ORDER_ORDERID_WRITE: 'ORDER_ORDERID_WRITE',
  COUPON_CREATION_WRITE: 'COUPON_CREATION_WRITE',
  REVIEW_PANEL_WRITE: 'REVIEW_PANEL_WRITE',
  STORES_REVIEW_WRITE: 'STORES_REVIEW_WRITE',
  LOVELOCAL_DAILY_WRITE: 'LOVELOCAL_DAILY_WRITE',
  SHARE_OFFER_CARD_WRITE: 'SHARE_OFFER_CARD_WRITE',
  SMS_SENT_WRITE: 'SMS_SENT_WRITE',
  BANNERS_WRITE: 'BANNERS_WRITE',
  CUSTOMERS_REVIEW_WRITE: 'CUSTOMERS_REVIEW_WRITE',
  PENDING_SETTLEMENTS_WRITE: 'PENDING_SETTLEMENTS_WRITE',
  RECONCILED_SETTLEMENTS_WRITE: 'RECONCILED_SETTLEMENTS_WRITE',
  DELIVERY_3_PL_SETTLEMENTS_WRITE: 'DELIVERY_3_PL_SETTLEMENTS_WRITE',
  PENDING_SETTLEMENTS_STORE_WRITE: 'PENDING_SETTLEMENTS_STORE_WRITE',
  FEEDS_SECTION_WRITE: 'FEEDS_SECTION_WRITE',
  GENERIC_BULK_UPLOAD_WRITE: 'GENERIC_BULK_UPLOAD_WRITE',
  COUPON_WRITE: 'COUPON_WRITE',
  COUPON_TRACKER_WRITE: 'COUPON_TRACKER_WRITE',
  QUERY_TRACKER_WRITE: 'QUERY_TRACKER_WRITE',
  REFERRAL_CHECK_WRITE: 'REFERRAL_CHECK_WRITE',
  SIGNIN_WRITE: 'SIGNIN_WRITE',
  PRODUCT_BULK_UPLOAD_WRITE: 'PRODUCT_BULK_UPLOAD_WRITE',
  PRICE_UPDATE_WRITE: 'PRICE_UPDATE_WRITE',
  PRODUCT_ADD_WRITE: 'PRODUCT_ADD_WRITE',
  STORE_WISE_JOB_STATUS_WRITE: 'STORE_WISE_JOB_STATUS_WRITE',
  SHOP_LOGO_REVIEW_WRITE: 'SHOP_LOGO_REVIEW_WRITE',
  RETAILER_STORY_REVIEW_WRITE: 'RETAILER_STORY_REVIEW_WRITE',
  FLASH_SALE_DETAILED_WRITE: 'FLASH_SALE_DETAILED_WRITE',
  FLASH_SALE_AGGREGATED_WRITE: 'FLASH_SALE_AGGREGATED_WRITE',
  STORIES_WRITE: 'STORIES_WRITE',
  CUSTOMER_WRITE: 'CUSTOMER_WRITE',
  REFUND_DASHBOARD_WRITE: 'REFUND_DASHBOARD_WRITE',
  SMART_CATALOGUES_WRITE: 'SMART_CATALOGUES_WRITE',
  SMART_CATALOGUES_STORE_WRITE: 'SMART_CATALOGUES_STORE_WRITE',
  SMART_CATALOGUE_MAP_WRITE: 'SMART_CATALOGUE_MAP_WRITE',
  LIBRARY_PRODUCT_REGIONAL_PRICE_WRITE: 'LIBRARY_PRODUCT_REGIONAL_PRICE_WRITE',
  LIBRARY_PRODUCTS_WRITE: 'LIBRARY_PRODUCTS_WRITE',
  BACKGROUND_JOBS_WRITE: 'BACKGROUND_JOBS_WRITE',
  FESTIVE_CATEGORY_WRITE: 'FESTIVE_CATEGORY_WRITE',

  LEAD_MANAGEMENT_WRITE: 'LEAD_MANAGEMENT_WRITE',
  CUSTOMER_PANEL_WRITE: 'CUSTOMER_PANEL_WRITE',
  OM_STATUS_TRACKER_WRITE: 'OM_STATUS_TRACKER_WRITE',
  STORE_RESURRECTION_WRITE: 'STORE_RESURRECTION_WRITE',
  CART_ABANDON_USER_WRITE: 'CART_ABANDON_USER_WRITE',
  COUPON_ENGINE_WRITE: 'COUPON_ENGINE_WRITE',
  CX_RX_CALL_MAPPING_WRITE: 'CX_RX_CALL_MAPPING_WRITE',
  BRAND_SAMPLING_CAMPAIGN_WRITE: 'BRAND_SAMPLING_CAMPAIGN_WRITE',
  FORCE_ADD_BULK_UPLOAD: 'FORCE_ADD_BULK_UPLOAD',
  FLASH_SALE_CAMPAIGN_WRITE: 'FLASH_SALE_CAMPAIGN_WRITE',
  SPECIAL_NOTES_WRITE: 'SPECIAL_NOTES_WRITE',
  STORE_MANAGEMENT_WRITE: 'STORE_MANAGEMENT_WRITE',

  // for dev purpose
  DEV_READ_READ: 'DEV_READ_READ',
  DEV_READ_WRITE: 'DEV_READ_WRITE',
};

export default permission;
